import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const BulkActions = ({ selectedRowIds, callBackFunction, newObject }) => {
  const [actionSelected, setActionSelected] = useState('');
  const navigate = useNavigate();
  console.log(selectedRowIds);
  return (
    <Row className="flex-between-center mb-3">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
          {Object.keys(selectedRowIds).length > 0
            ? 'You have selected ' +
              Object.keys(selectedRowIds).length +
              ' rows'
            : ''}
        </h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={event => {
                console.log(event.target.value);
                setActionSelected(event.target.value);
              }}
            >
              <option>Bulk Actions</option>
              <option value="delete">Delete</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={() => {
                callBackFunction(selectedRowIds, actionSelected);
                setActionSelected('');
              }}
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              className="me-2"
              onClick={() => {
                navigate(newObject.url);
              }}
            >
              <span className="d-none d-sm-inline-block ms-1">
                {newObject.title}
              </span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            ></IconButton>
          </div>
        )}
      </Col>
    </Row>
  );
};

BulkActions.propTypes = {
  callBackFunction: PropTypes.func,
  newObject: PropTypes.shape({
    title: PropTypes.any,
    url: PropTypes.any
  }),
  selectedRowIds: PropTypes.any
};

export default BulkActions;
