import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import EventService from './EventService';
import Select from 'react-select';
import DirectoryService from '../directory/DirectoryService';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';
import { Alert } from 'react-bootstrap';
const AddScheduleModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  setInitialEvents,
  initialEvents,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [formData, setFormData] = useState({});
  const [attendees, setAttendees] = useState({});
  const [videoConferenceLink, setVideoConferenceLink] = useState(false);
  const [validated, setValidated] = useState(false);
  const [labels, setLabels] = useState([]);
  const [error, setError] = useState('');
  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };
  const { instance, accounts } = useMsal();

  const handleChange = ({ target }) => {
    let name = target.name;

    let value = name === 'allDay' && target.value === 'on' ? true : false;
    value = name !== 'allDay' ? target.value : value;
    //alert(target.value);
    setFormData({ ...formData, [name]: value });

    console.log(formData);
  };
  const updateAttendees = value => {
    setAttendees(value);
    setFormData({ ...formData, attendeesValues: attendees });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    setValidated(true);
    // Date validation logic
    const startDate = moment(formData.startDate, 'DD/MM/YYYY h:mm aa');
    const endDate = moment(formData.endDate, 'DD/MM/YYYY h:mm aa');

    if (endDate.isBefore(startDate)) {
      setError('End date cannot be before start date.');
      return; // Prevent the form from being submitted
    } else {
      setError(''); // Clear any previous error
    }
    if (form.checkValidity()) {
      try {
        let event;
        if (accounts.length > 0) {
          if (formData.attendeesValues !== undefined) {
            formData.attendees = formData.attendeesValues;
          }
          event = await EventService.saveAzureCalendarEvent(
            formData,
            instance,
            accounts
          );
        } else {
          event = await EventService.saveManagementEvent(formData);
          console.log('Event saved successfully:', event);
        }
        setInitialEvents([...(initialEvents || []), event]);
        setIsOpenScheduleModal(false);
      } catch (error) {
        console.error('Error saving event:', error);
        // Handle the error or provide user feedback
      }
    }
  };
  const validateAttendees = () => {
    return formData.attendeesValues && formData.attendeesValues.length > 0
      ? true
      : false;
  };

  const [attendesItems, setAttendeesItems] = useState([]);

  //setLabels(labels);
  useEffect(() => {
    if (isOpenScheduleModal) {
      console.log(scheduleStartDate);
      console.log(scheduleEndDate);
      const updatedFormData = {
        ...formData,
        start: scheduleStartDate,
        end: scheduleEndDate,
        startDate: scheduleStartDate,
        endDate: scheduleEndDate
      };

      setFormData(updatedFormData);
    } else {
      setScheduleStartDate(null);
      setScheduleEndDate(null);
    }
    DirectoryService.retrieveUsersByAccount()
      .then(users => {
        const updatedItems = users.map(u => ({
          //value: u.identifier !== undefined > 0 ? u.identifier : u.email,
          value: u.identifier,
          label: u.firstName + ' ' + u.lastName
        }));
        setAttendeesItems(updatedItems);
        const labels = [
          {
            label: 'Business',
            value: 'Business'
          },
          {
            label: 'Important',
            value: 'Important'
          },
          {
            label: 'Personal',
            value: 'Personal'
          },
          {
            label: 'Must Attend',
            value: 'Must Attend'
          }
        ];
        setLabels(labels);
      })
      .catch(error => {
        console.error('Error retrieving users:', error);
      });
  }, [isOpenScheduleModal]);

  return (
    formData && (
      <Modal
        show={isOpenScheduleModal}
        onHide={handleClose}
        contentClassName="border"
      >
        {' '}
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5"> Create Schedule</Modal.Title>
          </Modal.Header>
          {error && (
            <Alert key="danger" variant="warning" className="mb-3">
              {error}
            </Alert>
          )}
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="title">
              <Form.Label className="fs-0">Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                required
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a title for the event
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="startDate">
              <Form.Label className="fs-0">Start Date</Form.Label>
              <DatePicker
                selected={formData.startDate}
                onChange={date => {
                  setScheduleStartDate(date);
                  setFormData({ ...formData, startDate: date, start: date });
                }}
                autoComplete="off"
                required
                className="form-control"
                placeholderText="DD/MM/YYYY H:M"
                dateFormat="dd/MM/yyyy h:mm aa"
                showTimeSelect
                name="startDate"
                value={formData.startDate}
              />
              <Form.Control.Feedback type="invalid">
                Please a valide start date for the event
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="endDate">
              <Form.Label className="fs-0">End Date</Form.Label>
              <DatePicker
                selected={scheduleEndDate}
                onChange={date => {
                  setScheduleEndDate(date);
                  setFormData({ ...formData, endDate: date, end: date });
                }}
                autoComplete="off"
                required
                className="form-control"
                placeholderText="DD/MM/YYYY H:M"
                dateFormat="dd/MM/yyyy h:mm aa"
                showTimeSelect
                name="endDate"
                value={scheduleStartDate}
              />
              <Form.Control.Feedback type="invalid">
                Please a valide end date for the event
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="attendees">
              <Form.Label className="fs-0">Attendees</Form.Label>
              <Select
                closeMenuOnSelect={false}
                options={attendesItems}
                placeholder="Select..."
                isMulti={true}
                classNamePrefix="react-select"
                value={formData.attendeesValues}
                onChange={value => updateAttendees(value)}
                className={validateAttendees() ? 'is-valid' : 'is-invalid'}
              />
              <Form.Control.Feedback type="invalid">
                Please the attendees for the events
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="allDay">
              <Form.Check
                type="switch"
                id="allDay"
                label="All Day"
                name="allDay"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fs-0">Schedule Meeting</Form.Label>
              <div>
                {!videoConferenceLink && (
                  <Button
                    as={Link}
                    variant="link"
                    to="#!"
                    // type="button"
                    className="badge-soft-success fw-medium"
                    size="sm"
                    onClick={() => {
                      setVideoConferenceLink(true);
                    }}
                  >
                    <FontAwesomeIcon icon="video" className="me-2" />
                    <span>Add video conference link</span>
                  </Button>
                )}
                {videoConferenceLink && (
                  <Form.Control
                    type="text"
                    name="url"
                    onChange={handleChange}
                  />
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label className="fs-0">Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                rows={3}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="label">
              <Form.Label className="fs-0">Label</Form.Label>
              <Select
                closeMenuOnSelect={false}
                name="label"
                key="label"
                options={labels}
                placeholder="Select..."
                required={true}
                isMulti={false}
                classNamePrefix="react-select"
                value={formData.label || ''}
                onChange={value => {
                  setValidated(true);
                  setFormData({ ...formData, label: value });
                }}
              />
              <Form.Control.Feedback type="invalid">
                Provide a label for the event
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer className="bg-light px-card border-top-0">
            <Button variant="primary" type="submit" className="px-4 mx-0">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  );
};

AddScheduleModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

export default AddScheduleModal;
