import React from 'react';
import { Card } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import AbsenceThisYear from '../../../dashboards/hr/AbsenceThisYear';
import HolidayService from '../../holidays/HolidayService';
import { Table } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';
import { Alert } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

class Absence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      absencesByUsers: [],
      showErrors: false,
      showList: false,
      errors: ''
    };
    this.deleteAbsence = this.deleteAbsence.bind(this);
  }
  componentDidMount() {
    HolidayService.retrieveAbsencesByProfile(this.props.profile.identifier)
      .then(data => {
        if (data.length > 0) {
          this.setState({ showList: true });
        }
        this.setState({ absencesByUsers: data });
      })
      .catch(error => {
        console.log(error);
        this.setState({ showErrors: true });
      });
  }
  deleteAbsence(identifier) {
    const absences = this.state.absencesByUsers.filter(
      a => a.identifier !== identifier
    );
    this.setState({ absencesByUsers: absences });
  }
  render() {
    return (
      <>
        <Card>
          <Card.Header>
            <h5 className="fs-0 mb-3">
              {' '}
              Absence -{' '}
              {this.props.profile.firstName + ' ' + this.props.profile.lastName}
            </h5>
          </Card.Header>
          {!this.state.showList && (
            <Card.Body>
              <Row>
                <Col>
                  <Alert variant="secondary" className="mb-0 rounded-0">
                    <p>No absences to show for this user</p>
                  </Alert>
                </Col>
              </Row>
            </Card.Body>
          )}
          {this.state.showList && (
            <Card.Body>
              <Row>
                <Col>
                  <AbsenceThisYear
                    data={HolidayService.getValuesForAbsencesChart(
                      this.props.profile,
                      []
                    )}
                    radius={['100%', '87%']}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th scope="col">Start</th>
                        <th scope="col">End</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Authorised by</th>
                        <th className="text-end" scope="col">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.absencesByUsers.map(abs => (
                        <tr key={abs.identifier}>
                          <td>{moment(abs.start).format('DD/MM/YYYY')}</td>
                          <td>{moment(abs.end).format('DD/MM/YYYY')}</td>
                          <td>{abs.type}</td>
                          <td>{abs.status}</td>
                          <td>
                            {this.props.profile.parents.filter(
                              p => p.identifier === abs.authorizedBy
                            )[0]?.email || 'You'}
                          </td>
                          <td className="text-end">
                            <ActionButton
                              onClick={() => alert(1)}
                              icon="edit"
                              title="Edit"
                              variant="action"
                              className="p-0 me-2"
                            />
                            <ActionButton
                              onClick={() => this.deleteAbsence(abs.identifier)}
                              icon="trash-alt"
                              title="Delete"
                              variant="action"
                              className="p-0"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </>
    );
  }
}
Absence.propTypes = {
  profile: PropTypes.shape({
    identifier: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    parents: PropTypes.arrayOf(PropTypes.object)
  })
};
export default Absence;
