import React, { useState, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HolidayService from './HolidayService';
import { Card } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import AdvanceList from '../common/AdvanceList';
import AbsenceThisYear from 'components/dashboards/hr/AbsenceThisYear';
import filterDataForList from '../common/functions/filterDataForList';
import { Link } from 'react-router-dom';
const Holidays = () => {
  const [data, setData] = useState();
  const [noDataToShow, setNoDataToShow] = useState(true);
  const [noDataTeamToShow, setNoDataTeamToShow] = useState(true);
  const [yourTeamData, setYourTeamData] = useState();
  const [profile, setProfile] = useState();
  const [holidaysForChart, setHolidaysForChart] = useState();
  const [absenceThisYear, setAbsenceThisYear] = useState();

  const navigate = useNavigate();

  const transformDate = inputDate => {
    if (inputDate === undefined) {
      return '';
    }
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    // const formattedDate = `${year}-${month}-${day}`;
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };
  const transformDataFunction = (attr, item) => {
    if (attr == 'id') {
      const url = '/app/holidays/' + item['identifier'];
      // I tried to use Link but didn't work
      return <Link to={url}>{item[attr]}</Link>;
    }
    /*if (attr == 'actions') {
      const identifier = item['identifier'];
      const approveHoliday = (identifier) => {
        HolidayService.approveHoliday(identifier).then(() => {
          console.log(identifier);
          //setStatusKey('APPROVED');
          //setIsOpenEventModal(!isOpenEventModal);
        });
      };
      const rejectHoliday = (identifier) => {
        HolidayService.rejectHoliday(identifier).then(() => {
          console.log(identifier);
          //setStatusKey('REJECTED');
          //setIsOpenEventModal(!isOpenEventModal);
        });
      };
      return (
        <>
          <ActionButton
            icon="thumbs-up"
            onClick={()=> approveHoliday(identifier)}
            title="Edit"
            variant="action"
            className="p-0 me-2"
          />
          <ActionButton
            icon="thumbs-down"
            onClick={()=> rejectHoliday(identifier)}
            title="Delete"
            variant="action"
            className="p-0"
          />
        </>
      );
    }*/
    return item[attr];
  };

  const fetchProfile = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    setProfile(profile);

    return profile;
  };

  const processAbsences = (response, profile, isTeam = false) =>
    response
      .filter(h =>
        isTeam
          ? h.profileIdentifier !== profile.identifier
          : h.profileIdentifier === profile.identifier
      )
      .map(h => ({
        ...h,
        requestedBy: `${h.profile.firstName} ${h.profile.lastName}`,
        start: transformDate(h.start),
        end: transformDate(h.end),
        id: isTeam
          ? `${h.profile.firstName} ${h.profile.lastName} (${transformDate(
              h.start
            )} - ${transformDate(h.end)})`
          : `${transformDate(h.start)} - ${transformDate(h.end)}`
      }));

  const fetchAbsencesData = async () => {
    try {
      const profile = fetchProfile();
      const response = await HolidayService.retrieveAbsencesThisYear();

      const holidaysForChart = response.filter(
        h => h.profileIdentifier === profile.identifier
      );
      setHolidaysForChart(holidaysForChart);

      const absenceThisYear = await HolidayService.getValuesForAbsencesChart(
        profile,
        holidaysForChart
      );
      setAbsenceThisYear(absenceThisYear);

      const absencesResponse = await HolidayService.retrieveAbsences();

      const updatedData = processAbsences(absencesResponse, profile);
      const yourTeamData = processAbsences(absencesResponse, profile, true);

      setData(
        filterDataForList(
          updatedData,
          columns.map(c => c.accessor),
          transformDataFunction
        )
      );
      setYourTeamData(
        filterDataForList(
          yourTeamData,
          columns.map(c => c.accessor),
          transformDataFunction
        )
      );

      setNoDataToShow(updatedData.length === 0);
      setNoDataTeamToShow(yourTeamData.length === 0);
    } catch (error) {
      console.error('Error fetching absences data:', error);
    }
  };

  const columns = useMemo(
    () => [
      { accessor: 'id', Header: 'Id' },
      { accessor: 'dateCreated', Header: 'Day requested' },
      { accessor: 'type', Header: 'Type' },
      { accessor: 'status', Header: 'Status' }
    ],
    []
  );

  useEffect(() => {
    fetchAbsencesData();
    console.log(profile); //DELETE this logic
    console.log(holidaysForChart);
  }, []); // Empty dependency array ensures this runs once on mount

  const buttonsConf = [
    {
      name: 'Book a new holiday',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Create a new Goal');
        navigate('/app/holidays/create-form');
      }
    },
    {
      name: 'See calendar   ',
      type: 'submit',
      variant: 'success',
      onClick: () => {
        navigate('/app/holidays/calendar');
      }
    }
  ];
  const holidayDescription =
    'Effortlessly manage time off with our intuitive Holidays feature. Create and track holidays, empower employees to request leave, and streamline approval processes, all within a user-friendly interface designed to enhance your holiday management experience.';
  const headerGenerated = useHeaderGenerator(
    'Holidays',
    holidayDescription,
    buttonsConf
  );
  return (
    <>
      {headerGenerated}
      <Row className="g-3 mb-3">
        <Col xxl={6} lg={12}>
          {noDataToShow && (
            <Card>
              <Alert key="warning" variant="warning">
                There's not any Holiday to show. Let's create a new one!
              </Alert>
            </Card>
          )}
          {!noDataToShow && (
            <>
              <AdvanceList
                columns={columns}
                data={data}
                title={'Annual Leaves'}
                newObject={{
                  url: '/app/holidays/create-form',
                  title: 'Book a new holiday'
                }}
              />
            </>
          )}
        </Col>
        <Col md={6}>
          {!noDataToShow && (
            <AbsenceThisYear
              data={absenceThisYear}
              radius={['100%', '67%']}
              customStyle={{ width: '13rem', height: '13rem' }}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {!noDataTeamToShow && (
            <AdvanceList
              columns={columns}
              data={yourTeamData}
              title={'Team holidays'}
              newObject={{
                url: '/app/holidays/create-form',
                title: 'Book a new holiday'
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={6} lg={12}>
          <Row>
            <Col lg={12}></Col>
            <Col lg={12}></Col>
          </Row>
        </Col>
        <Col xxl={{ span: 6, order: 1 }} lg={6}></Col>
        <Col xxl={6} lg={6}></Col>
        <Col xxl={{ span: 6, order: 1 }} lg={6}></Col>
        <Col xxl={{ span: 4, order: 3 }} lg={6}></Col>
        <Col xxl={{ span: 8, order: 2 }}></Col>
        <Col xxl={{ span: 12, order: 3 }}></Col>
      </Row>

      <Row className="g-3">
        <Col xxl={8}></Col>
        <Col xxl={4}></Col>
      </Row>
    </>
  );
};

export default Holidays;
