import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const FormModal = ({
  message,
  modalShow,
  setModalShow,
  redirectUrl = '/',
  title = 'Yes, you did it!'
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4></h4>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalShow(false);
              navigate(redirectUrl);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FormModal.propTypes = {
  message: PropTypes.any,
  modalShow: PropTypes.any,
  redirectUrl: PropTypes.string,
  setModalShow: PropTypes.func,
  title: PropTypes.string
};

export default FormModal;
