import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../common/FormModal';
import LeadService from './LeadService';

import schema from './jsonSchema';

const jsonSchema = schema;
const assignValueToJsonSchema = (field, sources) => {
  if (field.name === 'leadSource') {
    return {
      ...field,
      options: sources
    };
  }
  return field;
};

const mergedJsonSchema = (jsonSchema, sources) => {
  return jsonSchema.map(field => {
    if (field.type === 'group') {
      return {
        ...field,
        fields: field.fields.map(f => assignValueToJsonSchema(f, sources))
      };
    } else {
      return assignValueToJsonSchema(field, sources);
    }
  });
};
const LeadForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [leadSources, setLeadSources] = useState();
  const [title, setTitle] = useState('Create Lead');
  const [draft, setDraft] = useState(false);

  useEffect(() => {
    if (undefined !== identifier) {
      LeadService.retrieveLead(identifier)
        .then(data => {
          const updatedData = {
            ...data,
            leadSource: { label: data.leadSource, value: data.leadSource }
          };
          setData(updatedData);
          setTitle(updatedData.firstName + ' ' + updatedData.lastName);
        })
        .catch(error => console.log(error));
    }
    const leadSources = [
      {
        label: 'Website traffic',
        value: 'Website traffic'
      },
      {
        label: 'Email marketing',
        value: 'Email marketing'
      },
      {
        label: 'Content marketing',
        value: 'Content marketing'
      },
      {
        label: 'Social media',
        value: 'Social media'
      },

      {
        label: 'Trade shows and events',
        value: 'Trade shows and events'
      },
      {
        label: 'Partnerships and referrals',
        value: 'Partnerships and referrals'
      },
      {
        label: 'Cold calling',
        value: 'Cold calling'
      },
      {
        label: 'Direct mail',
        value: 'Direct mail'
      }
    ];
    setLeadSources(leadSources);
  }, [identifier]);
  const handleLeadFormSubmit = formData => {
    const updatedFormData = {
      ...formData,
      draft: draft
    };
    LeadService.saveLead(updatedFormData)
      .then(data => {
        const updatedData = {
          ...data,
          leadSource: { label: data.leadSource, value: data.leadSource }
        };
        setData(updatedData);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save Lead',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        setDraft(false);
        console.log('Save Lead');
      }
    },
    {
      name: 'Save as a draft',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        setDraft(true);
        console.log('Save as Draft');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator(title, '', []);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Lead  has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
          redirectUrl={'/app/leads'}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleLeadFormSubmit}
              sources={leadSources}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({ data, buttonsConf, onSubmit, sources }) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema, sources),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  onSubmit: PropTypes.any,
  sources: PropTypes.any
};
export default LeadForm;
