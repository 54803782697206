import DirectoryService from '../directory/DirectoryService.js';
import axios from 'axios';
const transformDate = s => {
  const originalDate = new Date(s);
  const formattedDate = originalDate.toLocaleDateString('en-GB');
  return formattedDate;
};
const RoleService = {
  retrieveRolePositionsByUser: async function () {
    const profile = DirectoryService.retrieveLoggedProfile();
    try {
      const response = await fetch(
        '/api/roleposition/profile/' + profile.identifier
      );
      console.log(response);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  publishOnLinkeIn: async function (accessToken, identifier) {
    console.log(identifier);
    const rolePosition = await this.retrieveRolePosition(identifier);
    const jobDetails = {
      title: rolePosition.header,
      description: rolePosition.jobDescription,
      employmentType: rolePosition.contractType,
      jobFunction: 'Engineering'
    };
    try {
      const response = await axios.post(
        'https://api.linkedin.com/v2/jobs',
        jobDetails,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Restli-Protocol-Version': '2.0.0'
          }
        }
      );

      console.log('Job posted successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error posting job:', error.response.data);
      throw error.response.data;
    }
  },
  retrieveRolePosition: async function (identifier) {
    try {
      const response = await fetch('/api/roleposition/' + identifier);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('There was an error!', error);
      throw error;
    }
  },
  updateStatus: async function (statusLog) {
    try {
      console.log(statusLog);
      const identifier = statusLog.identifier;
      delete statusLog.identifier;

      const url = '/api/roleposition/' + identifier + '/status';
      const method = 'POST';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(statusLog)
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  archiveRolePosition: async function (identifier) {
    try {
      const url = '/api/roleposition/' + identifier;
      const method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response);
    } catch (error) {
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  prepareRolePosition: function (rolePosition) {
    const profile = DirectoryService.retrieveLoggedProfile();
    const assignedIdentifier =
      rolePosition.assigned.value || rolePosition.assigned[0].value;
    const salaryLevel =
      rolePosition.salaryLevel.value || rolePosition.salaryLevel[0].value;
    const contractType =
      rolePosition.contractType.value || rolePosition.contractType[0].value;
    const status = rolePosition.status.value || rolePosition.status[0].value;
    const rolePositionWithRequestorIdentifier = {
      ...rolePosition,
      requestorIdentifier: profile.identifier,
      assignedIdentifier: assignedIdentifier,
      status: status,
      startDate: transformDate(rolePosition.startDate),
      contractType: contractType,
      salaryLevel: salaryLevel
    };

    delete rolePositionWithRequestorIdentifier.assigned;

    return rolePositionWithRequestorIdentifier;
  },
  saveRolePositionWithAttachement: function (rolePosition) {
    let url = '/api/roleposition/pdf';
    if (rolePosition.identifier !== undefined) {
      url = '/api/roleposition/' + rolePosition.identifier + '/pdf';
    }
    const file = rolePosition.file;
    delete rolePosition.file;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('payload', JSON.stringify(rolePosition));
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    if (rolePosition.identifier !== undefined) {
      axios.put(url, formData, config).then(response => {
        console.log(response.data);
      });
    } else {
      axios.post(url, formData, config).then(response => {
        console.log(response.data);
      });
    }
  },
  saveRolePosition: async function (rolePosition) {
    console.log(1);

    let url = '/api/roleposition';
    let method = 'POST';
    let status = 'REQUESTED';

    if (rolePosition.identifier !== undefined) {
      url = '/api/roleposition/' + rolePosition.identifier;
      method = 'PUT';
      status = rolePosition.status.value || rolePosition.status[0].value;
      console.log(status);
    }
    console.log(2);

    const rolePositionWithRequestorIdentifier =
      this.prepareRolePosition(rolePosition);
    console.log(rolePositionWithRequestorIdentifier);
    console.log(3);
    if (
      (rolePositionWithRequestorIdentifier.fileName === null &&
        rolePositionWithRequestorIdentifier.file === undefined) ||
      rolePositionWithRequestorIdentifier.file === undefined ||
      typeof rolePositionWithRequestorIdentifier.file === 'string'
    ) {
      console.log(4);
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(rolePositionWithRequestorIdentifier)
      });

      if (response.ok) {
        let newRolePosition = await response.json();
        console.log(newRolePosition);

        return newRolePosition;
      } else {
        throw new Error('Error saving Role Position');
      }
    }
    console.log(5);
    return this.saveRolePositionWithAttachement(
      rolePositionWithRequestorIdentifier
    );
  }
};

export default RoleService;
