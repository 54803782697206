const SalaryLevelService = {
  async saveSalaryLevel(formData) {
    try {
      let url = '/api/salarylevel';
      let method = 'POST';
      const profileLogged = JSON.parse(localStorage.getItem('profile'));
      const { accountIdentifier } = profileLogged;
      if (formData.identifier !== undefined) {
        url = '/api/salarylevel/' + formData.identifier;
        method = 'PUT';
      }
      const updatedData = { ...formData, accountIdentifier: accountIdentifier };
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData)
      });
      if (response.ok) {
        const newSalaryLevel = await response.json();
        console.log('Salary Level has been updated:', newSalaryLevel);
      }
    } catch (error) {
      console.log('Error saving Salary Level', error);
    }
  },
  async retrieveAllSalaryLevelsByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch(
        '/api/salarylevel/account/' + accountIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching SalaryLevel', error);
      throw error;
    }
  },
  async retrieveSalaryLevel(identifier) {
    try {
      const response = await fetch('/api/salarylevel/' + identifier);
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.error('Error fetching Salary Level', error);
      throw error;
    }
  },
  async deleteSalaryLevel(identifier) {
    try {
      let url = '/api/salarylevel/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newSalaryLevel = await response.json();
        console.log('SalaryLevel has been deleted:', newSalaryLevel);
      }
    } catch (error) {
      console.log('Error deleting SalaryLevel', error);
    }
  }
};
export default SalaryLevelService;
