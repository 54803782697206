const jsonSchema = [
  {
    type: 'group',
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        showInList: true,
        required: true
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        type: 'text'
      }
    ]
  },
  {
    type: 'group',
    fields: [
      {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
        showInList: true,
        required: true
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
        required: true,
        showInList: true
      }
    ]
  },
  {
    type: 'group',
    fields: [
      {
        name: 'company',
        label: 'Company',
        type: 'text'
      },
      {
        name: 'location',
        label: 'Location',
        type: 'text'
      }
    ]
  },
  {
    name: 'leadSource',
    label: 'Lead Source',
    type: 'multiselect',
    showInList: true,
    isMulti: false,
    required: true
  },
  {
    name: 'comments',
    label: 'Comments',
    type: 'editor',
    showInList: false
  }
];

export default jsonSchema;
