import React, { useState, useEffect } from 'react';
import NotificationService from './NotificationService';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import schema from './jsonSchema';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import useTableGenerator from '../common/functions/useTableGenerator';

const NotificationList = () => {
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);
  const [tableGenerated, setTableGenerated] = useState();
  const [refreshList, setRefreshList] = useState(false);

  const convertTime = time => {
    const date = new Date(time);
    return date.toLocaleDateString();
  };
  const deleteNotification = identification => {
    NotificationService.deleteNotification(identification)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log(error));
  };

  const jsonSchema = schema;
  useEffect(() => {
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);
    console.log(columns);
    NotificationService.retrieveAllNotificationByUser()
      .then(data => {
        const updatedData = data.map(n => {
          const owner = n.owner.firstName + ' ' + n.owner.lastName;
          const message = owner + ' ' + n.message;
          const time = convertTime(n.time);
          return { ...n, owner: n.owner.email, time: time, message: message };
        });

        const actionButtons = [
          {
            icon: 'trash-alt',
            onClick: identifier => {
              deleteNotification(identifier), setRefreshList(true);
            },
            className: 'p-0',
            title: 'Delete',
            variant: 'action'
          }
        ];
        setNoDataToShow(!updatedData.length > 0);
        setTableGenerated(
          useTableGenerator(updatedData, jsonSchema, actionButtons)
        );
        setRefreshList(false);
      })
      .catch(error => setError({ error: error.message }));
  }, [refreshList]);

  const buttonsConf = [];
  const headerGenerated = useHeaderGenerator('Notifications', ' ', buttonsConf);
  return (
    <>
      {error}
      {headerGenerated}
      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any Notification to show.
        </Alert>
      )}
      {tableGenerated}
    </>
  );
};

export default NotificationList;
