const jsonSchema = [
  {
    name: 'header',
    label: 'Header',
    type: 'text',
    showInList: true,
    required: true
  },
  {
    name: 'startDate',
    label: 'Start Date',
    type: 'date',
    pattern: 'dd/MM/yyyy',
    required: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'assigned',
        label: 'Assigned',
        type: 'multiselect',
        showInList: true,
        isMulti: false,
        required: true
      },

      {
        name: 'status',
        label: 'Status',
        type: 'multiselect',
        showInList: true,
        isMulti: false,
        required: true
      }
    ]
  },
  {
    name: 'file',
    type: 'fileUploader',
    label: 'Add a document (PDF/MS Word)',
    maxSize: 10,
    multiple: false,
    required: false,
    showInList: false
  },
  {
    type: 'group',
    fields: [
      {
        name: 'salaryLevel',
        label: 'Salary Level',
        type: 'multiselect',
        showInList: true,
        isMulti: false,
        required: true
      },
      {
        name: 'contractType',
        label: 'Contract Type',
        type: 'multiselect',
        showInList: true,
        isMulti: false,
        required: true
      }
    ]
  },
  {
    name: 'jobDescription',
    label: 'Description',
    type: 'editor',
    showInList: false,
    required: true
  }
];

export default jsonSchema;
