import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../common/FormModal';
import GroupService from './GroupService';

import schema from './jsonSchema';
import DirectoryService from '../directory/DirectoryService';
const jsonSchema = schema;
const assignValueToJsonSchema = (field, users, groupTypes) => {
  if (field.name === 'members') {
    return {
      ...field,
      options: users
    };
  }
  if (field.name === 'type') {
    return {
      ...field,
      options: groupTypes
    };
  }
  return field;
};
const mergedJsonSchema = (jsonSchema, users, groupTypes) => {
  return jsonSchema.map(field => {
    if (field.type === 'group') {
      return {
        ...field,
        fields: field.fields.map(f =>
          assignValueToJsonSchema(f, users, groupTypes)
        )
      };
    } else {
      return assignValueToJsonSchema(field, users, groupTypes);
    }
  });
};
const GroupForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [users, setUsers] = useState();
  const [groupTypes, setGroupTypes] = useState();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const groupTypes = [
      { label: 'Department', value: 'Department' },
      { label: 'Project', value: 'Project' }
    ];
    setGroupTypes(groupTypes);
    DirectoryService.retrieveUsersByAccount()
      .then(data => {
        const usersOptions = data.map(u => ({
          label: u.firstName + ' ' + u.lastName,
          value: u.identifier
        }));
        setUsers(usersOptions);
      })
      .catch(error => console.log(error));
    if (identifier) {
      GroupService.retrieveGroup(identifier)
        .then(data => {
          const membersValues = data.members.map(u => ({
            label: u.firstName + ' ' + u.lastName,
            value: u.identifier
          }));
          const type = { label: data.type, value: data.type };
          const updatedData = { ...data, members: membersValues, type: type };
          setData(updatedData);
        })
        .catch(error => console.log(error));
    }
  }, [identifier]);
  const handleGroupFormSubmit = formData => {
    console.log(formData);
    GroupService.saveGroup(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save Group',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save Group');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator('Create Group', ' ', []);
  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Group  has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
          redirectUrl={'/app/groups'}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleGroupFormSubmit}
              groupTypes={groupTypes}
              users={users}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({
  data,
  buttonsConf,
  onSubmit,
  users,
  groupTypes
}) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema, users, groupTypes),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  groupTypes: PropTypes.any,
  onSubmit: PropTypes.any,
  users: PropTypes.any
};
export default GroupForm;
