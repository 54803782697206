import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import OneStepRegistrationForm from '../directory/OneStepRegistrationForm';

const CompleteRegistration = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerId = queryParams.get('customer_id');
  const [personalInformation, setPersonalInformation] = useState();
  useEffect(() => {
    if (customerId) {
      const apiKey =
        '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';
      const apiUrl = `https://api.stripe.com/v1/customers/${customerId}`;
      const headers = {
        Authorization: `Bearer ${apiKey}`
      };

      axios
        .get(apiUrl, { headers })
        .then(response => {
          const data = response.data;
          console.log(data);
          setPersonalInformation(data);
        })
        .catch(error => {
          console.error('Error fetching payment intent:', error);
        });
    }
  }, []);

  return (
    <>
      {personalInformation && (
        <>
          <OneStepRegistrationForm
            name={personalInformation.name}
            email={personalInformation.email}
            phone={personalInformation.phone}
            numberOfEmployees={'Unlimited'}
          />
        </>
      )}
    </>
  );
};

export default CompleteRegistration;
