import axios from 'axios';
import DemoService from '../common/functions/DemoService';

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' }); // Change the type as needed
}

const DirectoryService = {
  async retrieveUsersByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch('/api/profile/account/' + accountIdentifier);
      const data = await response.json();
      if (localStorage.getItem('bstDemoMode') === 'true') {
        return data.concat(DemoService.retrieveDemoUsers());
      }
      return data;
    } catch (error) {
      console.error('Error fetching users', error);
      throw error;
    }
  },
  async savePassword(formData) {
    const { identifier } = formData;
    const url = '/api/profile/password/' + identifier;
    const method = 'PUT';
    if (formData.password !== formData.repeatPassword) {
      throw Error('Passwords fields are different!');
    }
    const user = {
      userName: formData.email,
      password: formData.password
    };
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching users', error);
      throw error;
    }
  },
  async sendRequestChangePassword(identifier) {
    try {
      const url = '/api/profile/password/request/' + identifier;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error requesting new password', error);
      throw error;
    }
  },

  async retrieveOutOfOfficeToday() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch(
        '/api/profile/account/' + accountIdentifier + '/off'
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching users', error);
      throw error;
    }
  },
  async retrieveProfile(identifier) {
    if (
      !this.isUUID(identifier) &&
      localStorage.getItem('bstDemoMode') === 'true'
    ) {
      const numericIdentifier = Number(identifier);
      const user = DemoService.retrieveDemoUsers().find(
        v => v.identifier === numericIdentifier
      );
      return user;
    }
    try {
      const response = await fetch('/api/profile/' + identifier);
      console.log(response.status);
      if (response.status === 404) {
        throw Error('404 Not Found');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching user', error);
      throw error;
    }
  },
  async deleteUserProfile(identifier) {
    try {
      const url = '/api/profile/' + identifier;
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error deleting user profile', error);
      throw error;
    }
  },
  isUUID(value) {
    // Ensure the value is a string
    const strValue = String(value);
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(strValue);
  },
  async saveContract(profileIdentifier, contract) {
    try {
      let url = '/api/contract/profile/' + profileIdentifier;
      let method = 'PUT';

      if (contract.identifier === undefined) {
        method = 'POST';
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(contract)
      });
      if (response.ok) {
        try {
          const newContract = await response.json();
          console.log('User Contract has been updated:', newContract);
          const profile = JSON.parse(localStorage.getItem('profile'));
          const profileUpdated = { ...profile, contract: newContract };

          localStorage.setItem('profile', JSON.stringify(profileUpdated));

          console.log('profile updated on localStorage');
        } catch (error) {
          console.log('Error recovering Profile', error);
          throw error;
        }
      }
    } catch (error) {
      console.log('Error saving Contract', error);
    }
  },
  async importProfile(row) {
    const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
    const url = '/api/profile/account/' + accountIdentifier;
    const method = 'POST';
    const profile = {
      firstName: row.firstName,
      lastName: row.lastName,
      dob: row.dob,
      email: row.email,
      address: row.address,
      personalMobile: row.personalMobile,
      employeeNumber: row.employeeNumber,
      roles: [{ key: 'HR-USER' }],
      contract: {
        jobType: row.jobType,
        startDate: row.startDate,
        contService: row.contService,
        benefitsStart: row.benefitsStart,
        department: row.departmnet
      }
    };
    console.log(profile);
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(profile)
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error importing user', error);
      throw error;
    }
  },
  async saveProfile(profile, userAvatar) {
    if (userAvatar === undefined) {
      try {
        let url = '/api/profile';
        let method = 'PUT';
        if (profile.identifier === undefined || profile.identifier === null) {
          const profileLogged = JSON.parse(localStorage.getItem('profile'));
          const { accountIdentifier } = profileLogged;
          url = '/api/profile/account/' + accountIdentifier;
          method = 'POST';
        }

        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(profile)
        });

        if (response.ok) {
          const newProfile = await response.json();
          console.log('User Profile has been created:', newProfile);
          const oldProfile = JSON.parse(localStorage.getItem('profile'));
          const updatedProfile = { ...profile, contract: oldProfile.contract };
          localStorage.setItem('profile', JSON.stringify(updatedProfile));
          console.log('Profile updated on localStorage');
        }
      } catch (error) {
        console.log('Error saving Profile', error);
      }
    } else {
      const formData = new FormData();

      formData.append('file', dataURItoBlob(userAvatar[0].base64));
      formData.append('profile', JSON.stringify(profile));
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      let url = '/api/profile';
      try {
        if (profile.identifier !== undefined) {
          url += '/' + profile.identifier;
          axios.put(url, formData, config).then(response => {
            console.log(response.data);
          });
        } else {
          axios.post(url, formData, config).then(response => {
            console.log(response.data);
          });
        }
      } catch (error) {
        console.log('Error saving Profile', error);
      }
    }
  },
  async retrieveContractByProfile(profileIdentifier) {
    try {
      const response = await fetch(
        '/api/contract/profile/' + profileIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user', error);
      throw error;
    }
  },
  retrieveUsersByAccountBackup: async function () {
    //return Promise.resolve(profiles)
    const users = await fetch('/api/profile')
      .then(response => {
        return response.json();
      })
      .catch(error => {
        //this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
    return users;
  },
  isLoggedByHRAdmin() {
    const profile = JSON.parse(localStorage.getItem('profile'));

    var root = profile.roles.filter(r => r.key === 'HR-ADMIN');
    if (root[0] !== undefined) {
      return true;
    }
    return false;
  },
  isLoggedUserRoot() {
    const profile = JSON.parse(localStorage.getItem('profile'));

    var root = profile.roles.filter(r => r.key === 'ROOT');
    if (root[0] !== undefined) {
      return true;
    }
    return false;
  },
  retrieveLoggedProfile() {
    const profile = JSON.parse(localStorage.getItem('profile'));
    return profile;
  },
  retrieveFollowers: async function () {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const users = await fetch(
      '/api/profile/account/' + profile.accountIdentifier
    )
      .then(response => {
        return response.json();
      })
      .then(users => {
        console.log(users);
        return users;
      })
      .catch(error => {
        //this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
    return users;
  },
  retrieveManagerForThisUser: function () {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const manager = profile.parents[0];
    return manager;
  },
  retrieveProfileIdentifier: function () {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      return profile.identifier;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async retrieveToDoList() {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const url = '/api/todo/profile/' + profile.identifier;
      const response = await fetch(url);
      const data = await response.json();
      if (data.length === 0) {
        const welcomeItems = [
          {
            identifier: 2,
            done: true,
            read: true,
            status: 'PENDING',
            title: 'update information for the new employees',
            type: 'TASK'
          },
          {
            identifier: 1,
            done: true,
            read: true,
            status: 'PENDING',
            title: 'Create an account on Bright Staff Tool',
            type: 'TASK'
          }
        ];
        return welcomeItems;
      }
      return data;
    } catch (error) {
      console.log('Error featching TODO list', error);
      throw error;
    }
  },
  completeItem: async identifier => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      }
    };
    const url = '/api/todo/complete/' + identifier;
    const toDoItem = await fetch(url, requestOptions)
      .then(response => {
        return response.json();
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    return toDoItem;
  },
  createToDoItem: async (id, task) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      },
      body: JSON.stringify({
        identifier: id,
        title: task,
        type: 'TASK',
        status: 'PENDING',
        done: false,
        read: true
      })
    };
    const profile = JSON.parse(localStorage.getItem('profile'));
    const url = '/api/todo/profile/' + profile.identifier;

    const toDoItem = await fetch(url, requestOptions)
      .then(response => {
        return response.json();
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    return toDoItem;
  },
  removeToDoItem: async (id, task) => {
    console.log(task);
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      }
    };
    const url = '/api/todo/' + id;

    const toDoItem = await fetch(url, requestOptions)
      .then(response => {
        return response.json();
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    return toDoItem;
  }
};

export default DirectoryService;
