import PropTypes from 'prop-types';
import React from 'react';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { Row, Col, Card } from 'react-bootstrap';
import BulkActions from './BulkActions';

const AdvanceList = ({
  columns,
  data,
  searchBox,
  selection,
  setGlobalFilter,
  title,
  bulkActions,
  bulkActionsFx,
  newObject = {
    title: 'Create a new user',
    url: '/social/directory/user-details'
  }
}) => {
  return (
    <Card>
      {title !== undefined && (
        <Card.Header>
          <h5>{title}</h5>
        </Card.Header>
      )}
      <Card.Body>
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          sortable
          pagination
          perPage={20}
          selection={selection || false}
          selectionColumnWidth={30}
        >
          <Row className="flex-between-center">
            <Col xs="auto" sm={6} lg={7}></Col>
            <Col xs="auto" sm={6} lg={5}>
              {searchBox && (
                <AdvanceTableSearchBox
                  table
                  setGlobalFilter={setGlobalFilter}
                  placeholder="Search ..."
                />
              )}
            </Col>
          </Row>
          {bulkActions !== undefined && (
            <BulkActions
              table
              callBackFunction={bulkActionsFx}
              newObject={newObject}
            />
          )}
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

AdvanceList.propTypes = {
  bulkActions: PropTypes.bool,
  bulkActionsFx: PropTypes.func,
  columns: PropTypes.any,
  data: PropTypes.shape({
    length: PropTypes.any
  }),
  newObject: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  }),
  searchBox: PropTypes.bool,
  selection: PropTypes.bool,
  setGlobalFilter: PropTypes.any,
  title: PropTypes.any
};
// Set default props
AdvanceList.defaultProps = {
  searchBox: false,
  bulkActions: false,
  bulkActionsFx: () => {}
};
export default AdvanceList;
