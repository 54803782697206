const jsonSchema = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    showInList: true,
    required: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'min',
        label: 'Min(£)',
        type: 'text',
        prefix: '£',
        showInList: true,
        required: true
      },
      {
        name: 'max',
        label: 'Max(£)',
        type: 'text',
        prefix: '£',
        showInList: true,
        required: true
      }
    ]
  },
  {
    name: 'description',
    label: 'Description',
    type: 'editor',
    showInList: false,
    required: false
  }
];
export default jsonSchema;
