import React, { useState, useEffect } from 'react';
import GroupService from './GroupService';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './jsonSchema';
import AdvanceList from '../common/AdvanceList';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import { Link } from 'react-router-dom';

const GroupList = () => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);
  const navigate = useNavigate();
  const transformData = (attr, item) => {
    console.log(attr);
    if (attr == 'name') {
      const url = '/app/groups/' + item['identifier'];
      return <Link to={url}>{item[attr]}</Link>;
    }
    if (attr === 'description') {
      return <span dangerouslySetInnerHTML={{ __html: item[attr] }} />;
    }
    return item[attr];
  };
  useEffect(() => {
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    GroupService.retrieveAllGroupsByAccount()
      .then(data => {
        setData(
          filterDataForList(
            data,
            columns.map(c => c.accessor),
            transformData
          )
        );
        setNoDataToShow(!data.length > 0);
      })
      .catch(e => {
        setError({ error: e.message });
        console.log(error);
      });
  }, [noDataToShow]);
  const buttonsConf = [
    {
      name: 'Create a new Group',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Create a new Group');
        navigate('/app/groups/create-form');
      }
    }
  ];
  const groupDescription =
    'Organize your workforce with ease using our Group Management module. Whether you need to create groups based on departments, projects, or specific teams, this feature allows you to structure your organization efficiently. Assign users to multiple groups, manage permissions, and streamline communication within each group. This module enhances collaboration and ensures that everyone is connected and aligned with their specific tasks and goals, making it easier to manage both departmental and project-based work.';
  const headerGenerated = useHeaderGenerator(
    'Groups Management',
    groupDescription,
    buttonsConf
  );
  return (
    <>
      {headerGenerated}

      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any Group to show. Let's create a new one!
        </Alert>
      )}
      {data && (
        <AdvanceList
          columns={columns}
          data={data}
          newObject={{
            url: '/app/groups/create-form',
            title: 'Create a new Group'
          }}
        />
      )}
    </>
  );
};

export default GroupList;
