import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import FormModal from 'components/app/common/FormModal';
import SalaryLevelService from './SalaryLevelService';
import schema from './jsonSchema';
import DOMPurify from 'dompurify';
const SalaryLevelDetails = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [salaryLevel, setSalaryLevel] = useState();

  const sanitizeAndRenderHTML = htmlString => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    return { __html: sanitizedHTML };
  };

  const showValue = attributeName => {
    const value = salaryLevel[attributeName];
    if (attributeName === 'description') {
      return <div dangerouslySetInnerHTML={sanitizeAndRenderHTML(value)}></div>;
    }
    return salaryLevel[attributeName];
  };

  useEffect(() => {
    SalaryLevelService.retrieveSalaryLevel(identifier)
      .then(data => {
        setSalaryLevel(data);
      })
      .catch(error => {
        console.error('Error retrieving Salary Level', error);
      });
  }, []);

  const buttonsConf = [
    {
      name: 'Edit Salary Level',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Edit this salary level');
        navigate('/app/roles/salarylevels/' + identifier + '/edit');
      }
    },
    {
      name: 'Archive Salary Level',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Archive this job vacancy');
        SalaryLevelService.deleteSalaryLevel(identifier)
          .then(response => {
            console.log(response);
            navigate('/app/roles/salaryLevels');
          })
          .catch(error => console.log(error));
      }
    }
  ];

  const jsonSchema = schema;
  const headerGenerated = useHeaderGenerator('Salary Level', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Salary Level has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      {salaryLevel && (
        <>
          <Card>
            <Card.Body>
              {jsonSchema.map(field => {
                if (field.type === 'group') {
                  return field.fields.map(f => (
                    <Row key={f.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">{f.label + ': '}</p>
                      </Col>
                      <Col>{showValue(f.name)}</Col>
                    </Row>
                  ));
                } else {
                  return (
                    <Row key={field.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">
                          {field.label + ': '}
                        </p>
                      </Col>
                      <Col>{showValue(field.name)}</Col>
                    </Row>
                  );
                }
              })}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default SalaryLevelDetails;
