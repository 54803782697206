import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EquipmentService from './EquipmentService';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './jsonSchema';
import AdvanceList from '../common/AdvanceList';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';

const EquipmentList = ({ profile }) => {
  const profileIdentifier = profile.identifier;
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    EquipmentService.retrieveAllEquipmentsByProfile(profileIdentifier)
      .then(data => {
        setData(
          filterDataForList(
            data,
            columns.map(c => c.accessor)
          )
        );
        setNoDataToShow(!data.length > 0);
        console.log(data);
        console.log(noDataToShow);
      })
      .catch(error => setError(error.message));
  }, [noDataToShow]);
  const buttonsConf = [
    {
      name: 'Create a new Equipment',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Create a new Equipment');
        navigate('/app/equipments/create-form');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator(
    'Create Equipment',
    ' ',
    buttonsConf
  );
  return (
    <>
      {headerGenerated}
      {error}
      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any Equipment to show. Let's create a new one!
        </Alert>
      )}
      {!noDataToShow && <AdvanceList columns={columns} data={data} />}
    </>
  );
};

EquipmentList.propTypes = {
  profile: PropTypes.shape({
    identifier: PropTypes.any
  })
};

export default EquipmentList;
