import React, { useState, useEffect } from 'react';
import RoleService from './RolesService';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './jsonSchema';
import AdvanceList from '../common/AdvanceList';
import { Link } from 'react-router-dom';
const RolePositionList = () => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);

  const transformData = (attr, item) => {
    if (attr == 'header') {
      const url = '/app/roles/' + item['identifier'];
      return <Link to={url}>{item[attr]}</Link>;
    }
    return item[attr];
  };
  useEffect(() => {
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    RoleService.retrieveRolePositionsByUser()
      .then(data => {
        const updatedData = data
          .filter(r => r.status !== 'ARCHIVED')
          .map(r => {
            r.assigned = r.assigned.firstName + ' ' + r.assigned.lastName;
            return r;
          });
        setData(
          filterDataForList(
            updatedData,
            columns.map(c => c.accessor),
            transformData
          )
        );
        setNoDataToShow(!updatedData.length > 0);
      })
      .catch(error => setError({ error: error.message }));
  }, [noDataToShow]);
  return (
    <>
      {error}
      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any Job Vacancy to show. Let's create a new one!
        </Alert>
      )}
      {data && (
        <AdvanceList
          columns={columns}
          data={data}
          newObject={{
            url: '/app/roles/role-form',
            title: 'Create job vaccancy'
          }}
        />
      )}
    </>
  );
};

export default RolePositionList;
