import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import AppContext from 'context/Context';
import AddScheduleModal from './AddScheduleModal';
import CalendarEventModal from './CalendarEventModal';
import Flex from 'components/common/Flex';
import EventService from './EventService';
import { Alert } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
const CalendarManagement = ({ data }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [day, setDay] = useState('');
  const [calendarDay, setCalendarDay] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState({});
  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [scheduleEndDate, setScheduleEndDate] = useState();
  const [initialEvents, setInitialEvents] = useState([]);
  const { instance, accounts } = useMsal();
  const handleEventClick = eventsInfo => {
    setModalEventContent({ event: calendarApi.getEventById(eventsInfo.id) });
    setIsOpenEventModal(true);
  };

  useEffect(() => {
    console.log(data);
    setCalendarApi(calendarRef.current.getApi());
    setInitialEvents(data);
    setIsOpenEventModal(false);

    console.log(title);
    console.log(day);
    console.log(calendarDay);

    const fetchAndMergeEvents = async () => {
      try {
        const abs = await EventService.retrieveManagementEventsForHome();
        const msEvents = await EventService.retrieveMicrosoftEvents(
          instance,
          accounts
        );

        // Merge the events arrays and set the merged state
        const combinedEvents = [...abs, ...msEvents];
        setInitialEvents(combinedEvents);
        setIsOpenScheduleModal(false);

        console.log('Merged Events:', combinedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchAndMergeEvents();
  }, []);
  const getDate = () => {
    return calendarApi.getCurrentData().currentDate.toLocaleString('en-us', {
      weekday: 'long'
    });
  };
  const getCalendarDay = () => {
    const currentDate = calendarApi.getCurrentData().currentDate;
    const weekday = currentDate.toLocaleString('en-us', { weekday: 'long' });
    const day = currentDate.getDate();
    const dayWithSuffix = getDayWithSuffix(day);
    return `${weekday} ${dayWithSuffix}`;
  };
  const getFormattedDate = () => {
    const date = new Date();
    const dayOfMonth = date.getDate();
    let ordinalIndicator;

    if (dayOfMonth >= 11 && dayOfMonth <= 13) {
      ordinalIndicator = 'th';
    } else {
      switch (dayOfMonth % 10) {
        case 1:
          ordinalIndicator = 'st';
          break;
        case 2:
          ordinalIndicator = 'nd';
          break;
        case 3:
          ordinalIndicator = 'rd';
          break;
        default:
          ordinalIndicator = 'th';
          break;
      }
    }

    return `${date.toLocaleString('en-us', {
      weekday: 'long'
    })} ${dayOfMonth}${ordinalIndicator}`;
  };
  const getDayWithSuffix = day => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    const suffix = ['st', 'nd', 'rd', 'th'];
    const index = (day % 10) - 1;
    return `${day}${suffix[index] || 'th'}`;
  };
  return (
    <>
      <Card className="overflow-hidden h-100">
        <Card.Body className="p-0 management-calendar">
          <Row className="g-3">
            <Col md={7}>
              <div className="p-card">
                <Flex justifyContent="between">
                  <div className="order-md-1">
                    <OverlayTrigger
                      overlay={<Tooltip id="nextTooltip">Previous</Tooltip>}
                    >
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="me-1"
                        onClick={() => {
                          calendarApi.prev();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                          setDay(getDate);
                          setCalendarDay(getCalendarDay);
                        }}
                      >
                        <FontAwesomeIcon icon="chevron-left" />
                      </Button>
                    </OverlayTrigger>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      onClick={() => {
                        calendarApi.today();
                        setTitle(calendarApi.getCurrentData().viewTitle);
                        setDay(getDate);
                        setCalendarDay(getCalendarDay);
                      }}
                      className="px-sm-4"
                    >
                      Today
                    </Button>
                    <OverlayTrigger
                      overlay={<Tooltip id="nextTooltip">Next</Tooltip>}
                    >
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          calendarApi.next();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                          setDay(getDate);
                          setCalendarDay(getCalendarDay);
                        }}
                      >
                        <FontAwesomeIcon icon="chevron-right" />
                      </Button>
                    </OverlayTrigger>
                  </div>

                  <IconButton
                    variant="falcon-primary"
                    iconClassName="me-2"
                    icon="plus"
                    size="sm"
                    onClick={() => {
                      setIsOpenScheduleModal(!isOpenScheduleModal);
                    }}
                  >
                    New Schedule
                  </IconButton>
                </Flex>
              </div>
              <div className="calendar-outline px-3">
                <FullCalendar
                  ref={calendarRef}
                  headerToolbar={false}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  themeSystem="bootstrap"
                  direction={isRTL ? 'rtl' : 'ltr'}
                  height={360}
                  dateClick={info => {
                    try {
                      const updatedDate = new Date(info.date);
                      updatedDate.setHours(9, 0, 0, 0); //To start at 9AM
                      setIsOpenScheduleModal(true);
                      setScheduleStartDate(updatedDate);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  events={initialEvents}
                  eventDidMount={info => {
                    //if (info.event.allDay) {
                    const eventEl = info.el;

                    // Adjust the event's width to cover the last day
                    const endDate = new Date(info.event.endStr); // Get the exclusive end date
                    endDate.setDate(endDate.getDate()); // Include the last day
                    const endDayStr = endDate.toISOString().split('T')[0];

                    // Get the calendar cell for the last day
                    const lastDayCell = document.querySelector(
                      `[data-date="${endDayStr}"]`
                    );
                    if (lastDayCell) {
                      const lastDayRect = lastDayCell.getBoundingClientRect();
                      const eventRect = eventEl.getBoundingClientRect();

                      // Extend the event width to cover the last day
                      eventEl.style.width = `${
                        lastDayRect.right - eventRect.left
                      }px`;
                    }
                    //}
                  }}
                />
              </div>
            </Col>
            <Col md={5} className="bg-light pt-3">
              <div className="px-3">
                <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                  {getFormattedDate()}
                </h4>
                <p className="text-500 mb-0">
                  {`${calendarApi.currentDataManager?.data?.viewTitle}`}
                </p>
                <ul
                  className="list-unstyled mt-3 scrollbar management-calendar-events"
                  id="management-calendar-events"
                >
                  {initialEvents.length === 0 && (
                    <>
                      <Alert key="warning" variant="warning">
                        <p className="fs--1">
                          There's not any events to show yet. Let's create a new
                          one!
                        </p>
                      </Alert>
                      <IconButton
                        variant="falcon-primary"
                        iconClassName="me-2"
                        icon="plus"
                        size="sm"
                        onClick={() => {
                          setIsOpenScheduleModal(!isOpenScheduleModal);
                        }}
                      >
                        Set a new Event
                      </IconButton>
                    </>
                  )}
                  {initialEvents.map(events => (
                    <li
                      className="border-top pt-3 mb-3 pb-1 cursor-pointer"
                      onClick={() => handleEventClick(events)}
                      key={events.id}
                    >
                      <div
                        className={`border-start border-3 ps-3 mt-1 border-${events.color}`}
                      >
                        <h6 className="mb-1 fw-semi-bold text-700">
                          {events.title}
                        </h6>
                        <p className="fs--2 text-600 mb-0">
                          {events.startTime || ''} {events.endTime ? '- ' : ''}
                          {events.endTime || ''}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <AddScheduleModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        setInitialEvents={setInitialEvents}
        initialEvents={initialEvents}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
      />

      <CalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        modalEventContent={modalEventContent}
      />
    </>
  );
};
CalendarManagement.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      color: PropTypes.string,
      classNames: PropTypes.string
    })
  )
};

export default CalendarManagement;
