import React from 'react';

import Personal from './user-details/Personal';
import Contract from './user-details/Contract';
import Absence from './user-details/Absence';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import DirectoryService from './DirectoryService';
import ProfileContract from './user-details/ProfileContract';
import FormModal from '../common/FormModal';
import ProfileBanner from './Banner.js';
import PasswordSettingsForm from './user-details/PasswordSettings';
import EquipmentList from '../equipments/EquipmentList';

function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouter;
}

class UserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 'contract',
      profile: null,
      contract: null,
      showError: false,
      showTabs: false,
      modalShow: false
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.setModalShow = this.setModalShow.bind(this);
  }
  retrieveEmptyProfile() {
    return {
      identifier: null,
      avatar: null,
      firstName: null,
      title: null,
      lastName: null,
      roles: [],
      contract: {
        identifier: null,
        lengthOfService: null,
        startDate: null,
        contService: null,
        benefitsStart: null,
        jobType: null,
        country: null,
        location: null,
        department: null,
        myLineManager: null,
        rightToWork: null,
        rightToWorkExpires: null,
        hoursPerWeek: null,
        daysPerWeek: null,
        fte: null,
        workPattern: null,
        holidayEntitlement: 0,
        holidayBroughtForward: 0,
        holidayTotalThisYear: 0,
        noticePeriod: null
      }
    };
  }
  updateProfile(updatedProfile, userAvatar) {
    DirectoryService.saveProfile(updatedProfile, userAvatar)
      .then(response => {
        console.log(response);
        this.setState({ modalShow: true });
      })
      .catch(error => console.log(error));
  }
  updateContract(profileIdentifier, updatedContract) {
    DirectoryService.saveContract(profileIdentifier, updatedContract).then(
      response => {
        console.log(response);
        this.setState({ showModal: true });
      }
    );
  }
  setModalShow(modalShow) {
    this.setState({ modalShow: modalShow });
  }
  componentDidMount() {
    const { identifier } = this.props.params;
    if (identifier !== undefined) {
      DirectoryService.retrieveProfile(identifier)
        .then(profile => {
          this.setState({
            profile: profile,
            contract:
              profile.contract !== null
                ? profile.contract
                : new ProfileContract(),
            showTabs: true
          });
        })
        .catch(() => {
          //this.setState({ showError: true });
          window.location.href = '/error/404';
        });
    } else {
      const emptyProfile = this.retrieveEmptyProfile();
      this.setState({
        profile: emptyProfile,
        contract: emptyProfile.contract,
        showTabs: true
      });
    }
  }

  render() {
    return (
      <>
        {this.state.modalShow && (
          <FormModal
            message={'User has been updated!'}
            modalShow={this.state.modalShow}
            setModalShow={this.setModalShow}
          />
        )}
        {this.state.showTabs && (
          <>
            <ProfileBanner profile={this.state.profile} />
            <Tabs defaultActiveKey={this.state.key} id="controlled-tab-example">
              <Tab
                eventKey="personal"
                title="Personal"
                className="border-bottom border-x p-3"
              >
                <Personal
                  profile={this.state.profile}
                  updateProfile={this.updateProfile}
                ></Personal>
              </Tab>
              <Tab
                eventKey="contract"
                title="Contract"
                className="border-bottom border-x p-3"
              >
                <Contract
                  contract={this.state.contract}
                  profile={this.state.profile}
                  updateContract={this.updateContract}
                ></Contract>
              </Tab>
              <Tab
                eventKey="absence"
                title="Absence"
                className="border-bottom border-x p-3"
              >
                <Absence profile={this.state.profile}></Absence>
              </Tab>
              <Tab
                eventKey="settings"
                title="Settings"
                className="border-bottom border-x p-3"
              >
                <PasswordSettingsForm profile={this.state.profile} />
              </Tab>
              <Tab
                tabClassName="d-none"
                eventKey="benefits"
                title="Benefits"
                className="border-bottom border-x p-3"
              ></Tab>
              <Tab
                eventKey="equipment"
                title="Equipment"
                className="border-bottom border-x p-3"
              >
                <EquipmentList profile={this.state.profile}></EquipmentList>
              </Tab>
              <Tab
                tabClassName="d-none"
                eventKey="training"
                title="Training"
                className="border-bottom border-x p-3"
              ></Tab>
            </Tabs>
          </>
        )}
      </>
    );
  }
}

UserDetails.propTypes = {
  params: PropTypes.shape({
    identifier: PropTypes.any
  })
};
const HOCUserDetails = withRouter(UserDetails);
export default HOCUserDetails;
