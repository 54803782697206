import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../../common/FormUtils';
import useHeaderGenerator from '../../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../../common/FormModal';
import SalaryLevelService from './SalaryLevelService';

import schema from './jsonSchema';
const jsonSchema = schema;

const SalaryLevelForm = () => {
  const formRef = useRef(null);
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (undefined !== identifier) {
      SalaryLevelService.retrieveSalaryLevel(identifier)
        .then(data => {
          setData(data);
        })
        .catch(error => console.log(error));
    }
  }, [identifier]);
  const handleSalaryLevelFormSubmit = formData => {
    SalaryLevelService.saveSalaryLevel(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save Salary Level',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log(formRef.current);
        if (formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', { bubbles: true }));
        }
        console.log('Save SalaryLevel');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator(
    'Create a Salary Level',
    ' ',
    buttonsConf
  );

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Salary range has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
          redirectUrl={'/app/roles/salarylevels'}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleSalaryLevelFormSubmit}
              formRef={formRef}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({ data, buttonsConf, onSubmit, formRef }) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit, handleChange } = useFormGenerator(
    jsonSchema,
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      ref={formRef} // This line assigns the form element to the formRef
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  formRef: PropTypes.any,
  onSubmit: PropTypes.any
};
export default SalaryLevelForm;
