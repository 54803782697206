import Alert from 'react-bootstrap/Alert';
import React, { useState, useEffect } from 'react';
import GoalService from './GoalService';

import { Card, Col, Row } from 'react-bootstrap';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './goalSchema';
import performanceSchema from './jsonSchema';
import ProgressBarWidget from '../common/functions/ProgressBarWidget';
import AdvanceList from '../common/AdvanceList';
import PerformanceReviewService from './PerformanceReviewService';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import { useNavigate } from 'react-router-dom';

const PerformanceManagementLandingPage = () => {
  const [data, setData] = useState();
  const [reviewData, setReviewData] = useState();
  const [columns, setColumns] = useState([]);
  const [reviewColumns, setReviewColumns] = useState([]);
  const [error, setError] = useState('');
  const [noGoalDataToShow, setNoGoalDataToShow] = useState(true);
  const [noReviewsDataToShow, setNoReviewsDataToShow] = useState(true);
  const [progressBarData, setProgressBarData] = useState();
  const navigate = useNavigate();
  const transformGoalDataFunction = (attr, item) => {
    console.log(attr);
    if (attr == 'name') {
      const url = '/app/performance/goal/' + item['identifier'] + '/edit';
      return <a href={url}>{item[attr]}</a>;
    }
    if (attr === 'deadline') {
      return convertTime(item[attr]);
    }
    return item[attr];
  };
  const convertTime = time => {
    const [year, month, day] = time.split('-').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day, 12));
    return date.toLocaleDateString();
  };
  const transformReviewsDataFunction = (attr, item) => {
    console.log(attr);
    if (attr == 'reviewDate') {
      const url = '/app/performance/' + item['identifier'] + '/edit';
      return <a href={url}>{item[attr]}</a>;
    }
    return item[attr];
  };
  useEffect(() => {
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    const jsonPerformanceSchema = performanceSchema;
    setReviewColumns(filterFieldsForList(jsonPerformanceSchema));

    PerformanceReviewService.retrieveAllPerformanceReviewByProfile()
      .then(data => {
        setReviewData(
          filterDataForList(
            data,
            reviewColumns.map(c => c.accessor),
            transformReviewsDataFunction
          )
        );
        setNoReviewsDataToShow(!data.length > 0);
      })
      .catch(error => setError({ error: error.message }));

    GoalService.retrieveAllGoalByAccount()
      .then(data => {
        setData(
          filterDataForList(
            data,
            columns.map(c => c.accessor),
            transformGoalDataFunction
          )
        );
        setNoGoalDataToShow(!data.length > 0);
        const progressBarData = data.map(goal => ({
          name: goal.name,
          progress: goal.progress
        }));

        setProgressBarData(progressBarData);
      })
      .catch(error => setError({ error: error.message }));
  }, [noGoalDataToShow, noReviewsDataToShow]);
  const buttonsConf = [
    {
      name: 'Create a new Goal',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Create a new Goal');
        navigate('/app/performance/goal-form');
      }
    },
    {
      name: 'Upload a review',
      type: 'submit',
      variant: 'success',
      onClick: () => {
        console.log('Create a new review');
        navigate('/app/performance/create-form');
      }
    }
  ];
  const performanceLandingPageDescription =
    'Enhance employee development with our dynamic Performance Management module. Set goals, conduct evaluations, and provide continuous feedback within an interactive and transparent system. This module supports personalized development plans and fosters a culture of growth and achievement, aligning individual performance with organizational objectives.';
  const headerGenerated = useHeaderGenerator(
    'Performance & Goals',
    performanceLandingPageDescription,
    buttonsConf
  );
  return (
    <>
      {headerGenerated}
      {error}
      <Row className="g-3 mb-3">
        <Col xxl={8}>
          <Card>
            <Card.Header>
              <h5 className="display-6 mb-0">Goals</h5>
            </Card.Header>
            <Card.Body>
              {noGoalDataToShow && (
                <Alert key="warning" variant="warning" className="fs--1">
                  There's not any Goal to show. Let's create a new one!
                </Alert>
              )}
              {data && (
                <AdvanceList
                  columns={columns}
                  data={data}
                  newObject={{
                    url: '/app/performance/goal-form',
                    title: 'Create a new goal'
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xxl={4}>
          {progressBarData && <ProgressBarWidget goals={progressBarData} />}
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={8}>
          <Card>
            <Card.Header>
              <h5 className="display-6 mb-0">Reviews</h5>
            </Card.Header>
            <Card.Body>
              {noReviewsDataToShow && (
                <Alert key="warning" variant="warning" className="fs--1">
                  There's not any Review to show. Let's create a new one!
                </Alert>
              )}
              {!noReviewsDataToShow && (
                <AdvanceList
                  columns={reviewColumns}
                  data={reviewData}
                  newObject={{
                    url: '/app/performance/create-form',
                    title: 'Upload a Review'
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xxl={4}></Col>
      </Row>
    </>
  );
};

export default PerformanceManagementLandingPage;
